import NewsTicker, { TickerStates } from "components/news-ticker"
import React, { useRef, useState } from "react"
import {
  faBolt,
  faSpinner,
  faThumbsUp,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LayoutCekilis from "components/layout-cekilis"
import { RaffleTypes } from "components/layout-cekilis"
import SEO from "components/seo"
import ShareButton from "components/share-button"
import useTranslations from "components/use-translations"

export default function AfilliCekilis() {
  const {
    cekilis: {
      katilimciListesiPlaceHolder,
      cekilisiBaslat,
      cekilisiSonlandir,
      cekilisYapiliyor,
      kazanan,
      afilliSeoTitle,
      afilliSeoDesc,
      afilliCekilisYap,
      isimleriListeyeYazipCekilisiBaslatin,
    },
  } = useTranslations()
  const [startedAsEmpty, setStartedAsEmpty] = useState(false)
  const [participants, setParticipants] = useState([])
  const [speedAdd, setSpeedAdd] = useState(1)
  const [speed, setSpeed] = useState(1)
  const [winner, setWinner] = useState("")
  const [lastWinner, setLastWinner] = useState("")
  const [duration, setDuration] = useState(0)
  const [shouldStop, setShouldStop] = useState(false)
  const [savedParticipants, setSavedParticipants] = useState([
    isimleriListeyeYazipCekilisiBaslatin,
  ])

  const [currentState, setCurrentState] = useState(TickerStates.Stopped)

  const tickerRef = useRef(null)

  const startRaffle = () => {
    if (currentState === TickerStates.Stopped) {
      let participantsCopy = participants.filter(p => p !== "")
      if (participantsCopy.length === 0) {
        setStartedAsEmpty(true)
        return
      }
      setSavedParticipants(participantsCopy)
      setStartedAsEmpty(false)

      setSpeed(1)
      setSpeedAdd(1)
      setDuration(0)
      tickerRef.current.start()
      setCurrentState(TickerStates.Running)
    } else if (currentState === TickerStates.Running) {
      setShouldStop(true)
    }
  }
  const getButtonContent = () => {
    if (shouldStop)
      return (
        <>
          <FontAwesomeIcon size="lg" pulse={true} icon={faSpinner} />
          <span className="ml-2">{cekilisYapiliyor}...</span>
        </>
      )
    else if (winner)
      return (
        <>
          <FontAwesomeIcon size="lg" icon={faTrophy} />
          <span className="ml-2">
            {kazanan}: {winner}
          </span>
        </>
      )
    else if (currentState === TickerStates.Running)
      return (
        <>
          <FontAwesomeIcon size="lg" icon={faThumbsUp} />{" "}
          <span className="ml-2">{cekilisiSonlandir}</span>
        </>
      )
    else
      return (
        <>
          <FontAwesomeIcon size="lg" icon={faBolt} />{" "}
          <span className="ml-2">{cekilisiBaslat}</span>
        </>
      )
  }
  return (
    <>
      <LayoutCekilis
        headerText={afilliCekilisYap}
        howToLink="https://i.hizliresim.com/6NpQG3.gif"
        type={RaffleTypes.Afilli}
      >
        <SEO
          title={afilliSeoTitle}
          description={afilliSeoDesc}
          pathname="https://www.kodvizit.com/cekilis/afillicekilis"
          datePublished="2017-02-08T22:39:04+00:00"
          dateModified="2020-08-05T16:53:13+00:00"
        />
        <textarea
          placeholder={katilimciListesiPlaceHolder}
          className={`form-control mb-4 text-secondary ${
            startedAsEmpty && "is-invalid"
          } `}
          id="katilimcilar"
          name="katilimcilar"
          rows="8"
          onChange={e => {
            if (startedAsEmpty) setStartedAsEmpty(false)
            setParticipants(e.target.value.split("\n"))
          }}
          value={participants.join("\n")}
          spellCheck="false"
        />
        <NewsTicker
          rowHeight={80}
          maxRows={1}
          duration={duration}
          speed={speed}
          ref={tickerRef}
          autoStart={false}
          pauseOnHover={true}
          items={savedParticipants}
          hasMoved={() => {
            if (speed > 1400) {
              tickerRef.current.stop()
              setWinner(tickerRef.current.state.items[0])
              setLastWinner(tickerRef.current.state.items[0])
              setCurrentState(TickerStates.Stopped)
              setShouldStop(false)
              setTimeout(() => {
                setWinner("")
              }, 4000)
            } else if (shouldStop) {
              setSpeedAdd(speedAdd * 1.4)
              setSpeed(speed + speedAdd)
              setDuration(speed + 25)
            }
          }}
        />
        <button
          onClick={startRaffle}
          className={`btn w-100 btn-${
            shouldStop
              ? "warning"
              : winner
              ? "success"
              : currentState === TickerStates.Running
              ? "danger"
              : "info"
          }`}
          style={{ height: 60 }}
        >
          {getButtonContent()}
        </button>
        <div className="d-flex align-items-center my-5">
          {currentState !== TickerStates.Running && !shouldStop && lastWinner && (
            <div className="mx-auto" style={{ maxWidth: 400 }}>
              <ShareButton
                type={RaffleTypes.Afilli}
                participants={participants}
                winners={[lastWinner]}
              />
            </div>
          )}
        </div>
      </LayoutCekilis>
    </>
  )
}
