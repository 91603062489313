import React, { Component } from "react"

import PropTypes from "prop-types"
import "./index.css"
export const Directions = Object.freeze({ UP: 1, DOWN: 2 })
export const TickerStates = Object.freeze({ Stopped: 0, Running: 1, Paused: 2 })

class NewsTicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      speed: this.props.speed,
      currState: 0,
      paused: 0,
      moving: 0,
      moveInterval: null,
      items: this.props.items,
    }
    this.element = React.createRef()
  }
  componentDidMount() {
    this.init()
  }

  init() {
    this.element.current.style.height = `${
      this.props.rowHeight * this.props.maxRows
    }px`
    this.element.current.style.overflow = `hidden`
    this.checkSpeed()

    if (
      this.props.nextButton &&
      typeof this.props.nextButton[0] !== "undefined"
    )
      this.props.nextButton.click(
        function () {
          this.moveNext()
          this.resetInterval()
        }.bind(this)
      )
    if (
      this.props.prevButton &&
      typeof this.props.prevButton[0] !== "undefined"
    )
      this.props.prevButton.click(
        function () {
          this.movePrev()
          this.resetInterval()
        }.bind(this)
      )
    if (
      this.props.stopButton &&
      typeof this.props.stopButton[0] !== "undefined"
    )
      this.props.stopButton.click(
        function () {
          this.stop()
        }.bind(this)
      )
    if (
      this.props.startButton &&
      typeof this.props.startButton[0] !== "undefined"
    )
      this.props.startButton.click(
        function () {
          this.start()
        }.bind(this)
      )

    if (this.props.autoStart) this.start()
  }

  async start() {
    if (!this.state.currState) {
      await this.setState((_, props) => ({ items: props.items }))
      await this.setState({ currState: 1 })
      this.resetInterval()
      this.props.started()
    }
  }
  async stop() {
    if (this.state.currState) {
      clearInterval(this.state.moveInterval)
      await this.setState({ currState: 0 })
      this.props.stopped()
    }
  }

  async resetInterval() {
    if (this.state.currState) {
      clearInterval(this.state.moveInterval)
      await this.setState({
        moveInterval: setInterval(
          function () {
            this.move()
          }.bind(this),
          this.props.duration
        ),
      })
    }
  }
  move() {
    if (!this.state.paused) this.moveNext()
  }
  moveNext() {
    if (this.props.direction === Directions.DOWN) this.moveDown()
    else this.moveUp()
  }
  movePrev() {
    if (this.props.direction === Directions.DOWN) this.moveUp()
    else this.moveDown()
  }
  async pause() {
    if (!this.state.paused) await this.setState({ paused: 1 })
    this.props.paused()
  }
  async unpause() {
    if (this.state.paused) await this.setState({ paused: 0 })
    this.props.unpaused()
  }

  async handleDownAnimation(newList) {
    const firsLiEl = this.element.current.children[0]
    const speed = this.props.speed

    // Hide first li element at up
    firsLiEl.style.cssText = `margin: -${this.props.rowHeight}px`

    // First element will go down in speed ms
    setTimeout(async () => {
      firsLiEl.style.cssText = `margin: 0;` + `transition: all ${speed}ms;`
    }, 0)

    // Wait for speed ms and add last element to beginning of the list.
    await new Promise(resolve =>
      setTimeout(async () => {
        await this.setState({ items: newList })
        resolve()
      }, speed)
    )
  }

  async moveDown() {
    if (this.state.moving) return

    await this.setState({ moving: 1 })
    this.props.movingDown()

    const itemsCopy = [...this.state.items]
    itemsCopy.unshift(itemsCopy.pop())

    await this.handleDownAnimation(itemsCopy)

    await this.setState({ moving: 0 })
    this.props.hasMoved()
  }

  async handleUpAnimation(newList) {
    const firsLiEl = this.element.current.children[0]

    // First element will go up rowHeight px in speed ms
    setTimeout(
      () =>
        (firsLiEl.style.cssText =
          `margin: -${this.props.rowHeight}px 0 0 0;` +
          `transition: all ${this.props.speed}ms;`),
      0
    )

    // Wait for speed ms and send first element to end of the list.
    // After that get first list element back to margin 0.
    await new Promise(resolve =>
      setTimeout(async () => {
        await this.setState({ items: newList })
        firsLiEl.style.cssText = "margin: 0"
        resolve()
      }, this.props.speed)
    )
  }

  async moveUp() {
    if (this.state.moving) return

    await this.setState({ moving: 1 })
    this.props.movingUp()

    const itemsCopy = [...this.state.items]
    itemsCopy.push(itemsCopy.shift())

    await this.handleUpAnimation(itemsCopy)

    await this.setState({ moving: 0 })
    this.props.hasMoved()
  }
  getState() {
    if (this.state.paused) return TickerStates.Paused
    else
      return this.state.currState ? TickerStates.Running : TickerStates.Stopped
  }
  async checkSpeed() {
    if (this.props.duration < this.state.speed + 25)
      await this.setState((_, props) => ({ speed: props.duration - 25 }))
  }
  render() {
    return (
      <div className="text-center">
        <ul
          className="reactNewsTicker"
          ref={this.element}
          onMouseEnter={() => {
            this.props.pauseOnHover && this.state.currState && this.pause()
          }}
          onMouseLeave={() => {
            this.props.pauseOnHover && this.state.currState && this.unpause()
          }}
        >
          {this.state.items &&
            this.state.items.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
      </div>
    )
  }
}

NewsTicker.propTypes = {
  rowHeight: PropTypes.number,
  maxRows: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  speed: PropTypes.number,
  duration: PropTypes.number,
  direction: PropTypes.number,
  autoStart: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  nextButton: PropTypes.node,
  prevButton: PropTypes.node,
  startButton: PropTypes.node,
  stopButton: PropTypes.node,
  hasMoved: PropTypes.func,
  movingUp: PropTypes.func,
  movingDown: PropTypes.func,
  started: PropTypes.func,
  stopped: PropTypes.func,
  paused: PropTypes.func,
  unpaused: PropTypes.func,
}
NewsTicker.defaultProps = {
  rowHeight: 20,
  items: [],
  maxRows: 3,
  speed: 400,
  duration: 2500,
  direction: Directions.UP,
  autoStart: true,
  pauseOnHover: true,
  nextButton: null,
  prevButton: null,
  startButton: null,
  stopButton: null,
  hasMoved: function () {},
  movingUp: function () {},
  movingDown: function () {},
  started: function () {},
  stopped: function () {},
  paused: function () {},
  unpaused: function () {},
}
export default NewsTicker
